import { request } from './request'

/*我收藏信息列表/我关注的商家/我的粉丝*/
export function queryMyfocus(focus_type, p) {
  return request({
    url: '/user/myfocus',
    params: {
      focus_type,
      p
    }
  })
}

/**
 * 我发布的信息
 */
export function mySupply(p) {
  return request({
    url: '/user/my_supply',
    params: {
      p
    }
  })
}

export function getFans(id) {
  return request({
    url: '/follow/findfans',
    params: {
      id
    }
  })
}

/**
 *  添加/取消关注用户/商家
 *  type:0 添加
 *  type:1 取消
 */
export function followUser(store_id, type) {
  return request({
    url: '/Merchant/collect_store',
    params: {
      store_id,
      type
    }
  })
}

/**
 *  添加/取消收藏信息
 *  type:0 添加收藏
 *  type:1 取消收藏
 */
export function addfollow(t_id, type) {
  return request({
    url: '/trade/collect_trade',
    params: {
      t_id,
      type
    }
  })
}
